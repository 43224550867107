<template>
  <div
    class="pt-[4.25rem] px-2 overflow-y-auto border-r border-neutral-300 dark:border-neutral-800 bg-white dark:bg-neutral-900 space-y-1"
  >
    <StudioMenuOrgSelector />
    <StudioMenuNavItem v-for="item in nav" :nav-item="item" />
  </div>
</template>

<script setup lang="ts">
import {
  IconBook2,
  IconChartHistogram,
  IconCurrencyDollar,
  IconLayoutDashboard,
  IconPlaceholder,
  IconSettings,
  IconUsers,
  IconAtom,
} from "@tabler/icons-vue";
import StudioMenuNavItem from "~/components/studio/StudioMenuNavItem.vue";
import StudioMenuOrgSelector from "~/components/studio/StudioMenuOrgSelector.vue";

const props = defineProps<{
  orgId?: string;
}>();

const nuxtApp = useNuxtApp();
const href = useHref();
const translate = nuxtApp.$i18n.global.t;

type NavItem = {
  name: string;
  icon?: typeof IconPlaceholder;
  href?: string;
  children?: NavItem[];
  noNesting?: boolean;
};

// TODO: dedupe this
const nav = computed<NavItem[]>(() => {
  const finalNav: NavItem[] = [
    // {
    //   name: translate("layouts.studio.organizations"),
    //   icon: IconList,
    //   href: `/studio`,
    // },
  ];

  if (!props.orgId) return finalNav;

  finalNav.push(
    ...[
      {
        name: translate("layouts.studio.dashboard"),
        icon: IconLayoutDashboard,
        href: `/studio/${props.orgId}`,
        noNesting: true,
      },
      {
        name: translate("layouts.studio.insights"),
        icon: IconChartHistogram,
        href: `/studio/${props.orgId}/insights`,
      },
      {
        name: translate("layouts.studio.audience"),
        icon: IconUsers,
        children: [
          {
            name: translate("layouts.studio.content"),
            children: [
              {
                name: translate("layouts.studio.overview"),
                href: `/studio/${props.orgId}/audience-overview/summary`,
              },
              {
                name: translate("layouts.studio.demographics"),
                href: `/studio/${props.orgId}/audience-demographics`,
              },
              {
                name: translate("layouts.studio.acquisition"),
                href: `/studio/${props.orgId}/audience-acquisition/internal`,
              },
            ],
          },
          // {
          //   name: translate("layouts.studio.social"),
          //   children: [
          //     {
          //       name: translate("layouts.studio.overview"),
          //       href: `/studio/${props.orgId}/audience-overview`,
          //     },
          //     {
          //       name: translate("layouts.studio.demographics"),
          //       href: `/studio/${props.orgId}/audience-demographics`,
          //     },
          //     {
          //       name: translate("layouts.studio.acquisition"),
          //       href: `/studio/${props.orgId}/audience-acquisition`,
          //     },
          //   ],
          // },
        ],
      },
      {
        name: translate("layouts.studio.content"),
        icon: IconBook2,
        children: [
          {
            name: translate("layouts.studio.titles"),
            href: `/studio/${props.orgId}/titles`,
          },
          //{ name: 'Chapters', to: `/studio/${ props.orgId }/chapters` },
        ],
      },
      {
        name: "Engagement",
        icon: IconAtom,
        children: [
          {
            name: "Emotes",
            href: href.value.studio.org(props.orgId).experience.emotes.index,
          },
          {
            name: "Stickers",
            href: href.value.studio.org(props.orgId).experience.stickers.index,
          },
          {
            name: "Avatar Frames",
            href: href.value.studio.org(props.orgId).experience.frames.index,
          },
        ],
      },
      {
        name: translate("layouts.studio.subscriptions"),
        icon: IconCurrencyDollar,
        children: [
          {
            name: translate("layouts.studio.overview"),
            href: `/studio/${props.orgId}/subscriptions/overview`,
          },
          {
            name: "Insights",
            href: `/studio/${props.orgId}/subscriptions/insights`,
          },
          {
            name: translate("layouts.studio.balance"),
            href: `/studio/${props.orgId}/subscriptions/balance`,
          },
          {
            name: "Tiers",
            href: `/studio/${props.orgId}/subscriptions/tiers`,
          },
          {
            name: "Account",
            href: `/studio/${props.orgId}/subscriptions/account`,
          },
        ],
      },
      {
        name: translate("layouts.studio.settings"),
        icon: IconSettings,
        href: `/studio/${props.orgId}/settings`,
      },
    ].filter(onlyTruthys),
  );

  return finalNav;
});
</script>

<style scoped lang="postcss"></style>
