<template>
  <div class="studio-nav-item relative !block">
    <ContextMenu close-on-outside-click>
      <template #facing="{ open }">
        <div @click="open" class="flex items-center w-full">
          <OrganizationCardDense
            v-if="currentOrg"
            :org="currentOrg"
            class="!py-0 min-w-0 text-sm !items-center"
            plain
            name-only
            no-link
          >
            <template #subtext><span></span></template>
          </OrganizationCardDense>
          <div v-else class="flex items-center space-x-3 px-3 py-2">
            <IconBuilding :size="24" />
            <div class="ml-2 text-sm line-clamp-2 font-medium">
              All Organizations
            </div>
          </div>
          <span class="ml-auto flex-shrink-0">
            <IconChevronDown />
          </span>
        </div>
      </template>
      <template #menu="{ close }">
        <div
          class="!ml-0 tile shadow-md ring-1 ring-black ring-opacity-5 p-2 cursor-auto grid gap-1 overflow-y-auto max-viewport min-w-[240px] max-w-[320px]"
          @click.stop
        >
          <NamiInput
            v-model="searchText"
            pill
            block
            class="!min-h-[2rem]"
            placeholder="Search"
          />
          <NamiDivider class="!my-1" />
          <NamiLoading v-if="orgPending" size="small"></NamiLoading>
          <div v-else-if="orgList.length === 0" class="text-center">
            No Results
          </div>
          <template v-else>
            <TheNuxtLink
              :to="
                $route.params.org
                  ? {
                      name: $route.matched[$route.matched.length - 1]?.name,
                      params: { ...$route.params, org: org.id },
                    }
                  : `/studio/${org.id}`
              "
              class="studio-nav-item min-w-0"
              v-for="org in orgList"
              @click="() => (close(), clear())"
            >
              <OrganizationCardDense
                :org="org"
                no-link
                class="!py-0 min-w-0 text-sm !items-center"
                plain
                name-only
                ><template #subtext><span></span></template
              ></OrganizationCardDense>
            </TheNuxtLink>
          </template>
          <NamiDivider class="!my-1" />
          <TheNuxtLink
            to="/studio"
            class="studio-nav-item text-sm space-x-3 px-2 py-2"
            @click="() => (close(), clear())"
          >
            <IconBuilding class="mr-4" :size="24" />
            All Organizations
          </TheNuxtLink>
        </div>
      </template>
    </ContextMenu>
  </div>
</template>

<script setup lang="ts">
import { Organization, type OrganizationEntity } from "~/src/api";
import { IconBuilding, IconChevronDown } from "@tabler/icons-vue";
import { debounce } from "perfect-debounce";

const nuxtApp = useNuxtApp();
const route = useRoute();
const authStore = nuxtApp.$auth();

const orgList = ref<OrganizationEntity[]>([]);
const searchText = ref("");

const currentOrg = ref<OrganizationEntity>();

watch(
  () => route.params.org,
  (v) => {
    if (!v) {
      currentOrg.value = undefined;
      return;
    }
    if (v !== currentOrg.value?.id) setCurrentOrg(v as string);
  },
  { immediate: true },
);

const { action: fetchOrgs, pending: orgPending } = useAction2(async () => {
  const user = await authStore?.getUser();
  if (!user) throw new Error("Not logged in.");

  const [ownedOrgs, memberOrgs] = await Promise.all([
    Organization.search({ ownerId: user.profile.sub, name: searchText.value }),
    Organization.search({ memberId: user.profile.sub, name: searchText.value }),
  ]);

  const all: OrganizationEntity[] = ownedOrgs.data.concat(memberOrgs.data);
  const unique = [...new Set(all.map((x) => x.id))];

  orgList.value = unique.map((id) => all.find((org) => org.id === id)!);
  orgList.value.splice(5);
});

async function setCurrentOrg(id: string) {
  const foundOrg = orgList.value.find((org) => org.id === id);
  if (foundOrg) {
    currentOrg.value = foundOrg;
    return;
  }
  currentOrg.value = await Organization.get(id, []);
}

async function clear() {
  searchText.value = "";
  fetchOrgs();
}

const debouncedSearch = debounce(fetchOrgs, 300);
watch(searchText, () => debouncedSearch());

onMounted(() => {
  if (orgList.value.length === 0) {
    fetchOrgs();
  }
});
</script>

<style scoped lang="postcss">
.studio-nav-item {
  @apply hover:bg-neutral-100 dark:hover:!bg-neutral-800 dark:hover:!text-neutral-300 w-full rounded-md pr-3 flex items-center select-none space-x-2 cursor-pointer;

  &.hover {
    @apply bg-neutral-100 dark:!bg-neutral-800;
  }
}

.max-viewport {
  max-height: calc(100vh - 4rem - 100% - 1rem);
}
</style>
