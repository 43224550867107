<template>
  <DropArea
    :disabled="!meta.dropArea.enabled"
    :rules="meta.dropArea.rules"
    @filesdropped="(files) => drop(files)"
    overlay-fixed
  >
    <template v-if="meta.dropArea.component" #dropview>
      <component
        :is="meta.dropArea.component"
        v-bind="meta.dropArea.componentProps"
      />
    </template>
    <div class="text-neutral-800 dark:text-neutral-200">
      <TheDesktopNavbarTop
        class="fixed top-0 left-0 z-40 transition-transform shadow"
        :class="{
          '-translate-y-full sm:translate-y-0': scrollDirection === 'downward',
        }"
        :site-name="t('layouts.studio.site')"
      />

      <TheMobileNavbarBottom
        class="fixed bottom-0 left-0 z-40 transition-transform"
        :class="{
          'translate-y-full sm:translate-y-0': scrollDirection === 'downward',
        }"
        @open-navbar="navbarOpen = true"
        :nav="[
          {
            id: 'dashboard',
            name: t('layouts.studio.dashboard'),
            href: href.studio.org(orgId ?? '').dashboard,
            icon: IconLayoutDashboard,
            disabled: route.path === '/studio',
          },
          {
            id: 'titles',
            name: t('layouts.studio.titles'),
            href: href.studio.org(orgId ?? '').titles.index,
            icon: IconBook2,
            disabled: route.path === '/studio',
          },
          {
            id: 'subscriptions',
            name: t('layouts.studio.subscriptions'),
            href: href.studio.org(orgId ?? '').subscriptions.overview,
            icon: IconCurrencyDollar,
            disabled: route.path === '/studio',
          },
          {
            id: 'menu',
            name: t('layouts.default.menu'),
            href: '#',
            icon: IconMenu2,
          },
        ]"
      />
    </div>

    <div class="bg-neutral-100 dark:bg-black">
      <StudioMenuNav
        class="hidden z-10 sm:fixed left-0 top-0 sm:inset-y-0 sm:flex sm:w-64 sm:flex-col h-full"
        :org-id="orgId"
      />

      <div class="sm:pl-64 min-h-[100dvh] pt-16">
        <main class="sm:px-4 lg:px-6 pt-4 pb-20 sm:py-4 lg:py-6">
          <NamiRequireAuth>
            <slot />
          </NamiRequireAuth>
        </main>
      </div>
    </div>
    <TheMobileExpandableSidebarLeft
      v-model="navbarOpen"
      :nav="nav"
      @change-nav="navbarOpen = false"
    />
  </DropArea>
</template>

<script setup lang="ts">
import type { Props as DesktopNavProps } from "~~/components/the/TheDesktopSidebarLeft.vue";
import {
  IconBook2,
  IconChartHistogram,
  IconCurrencyDollar,
  IconLayoutDashboard,
  IconList,
  IconMenu2,
  IconSettings,
  IconUsers,
  IconAtom,
} from "@tabler/icons-vue";

const nuxtApp = useNuxtApp();
const route = useRoute();
const href = useHref();
const { t } = nuxtApp.$i18n.global;
const scrollDirection = useScrollDirection();
const meta = usePageMeta();
const { drop } = useFileDropper();

const orgId = computed(() => route.params.org as string | undefined);

const navbarOpen = ref(false);

const nav = computed<DesktopNavProps["nav"]>(() => {
  const finalNav: DesktopNavProps["nav"] = {
    site: t("layouts.studio.site"),
    items: [
      {
        name: t("layouts.studio.organizations"),
        icon: IconList,
        href: `/studio`,
        noNestingLink: true,
      },
    ],
  };

  if (!orgId.value) return finalNav;

  finalNav.items.push(
    ...[
      {
        name: t("layouts.studio.dashboard"),
        icon: IconLayoutDashboard,
        href: href.value.studio.org(orgId.value).dashboard,
        noNestingLink: true,
      },
      {
        name: t("layouts.studio.insights"),
        icon: IconChartHistogram,
        href: href.value.studio.org(orgId.value).insights.index,
      },
      {
        name: t("layouts.studio.audience"),
        icon: IconUsers,
        children: [
          {
            name: t("layouts.studio.overview"),
            href: href.value.studio.org(orgId.value).audienceOverview.index,
          },
          {
            name: t("layouts.studio.demographics"),
            href: href.value.studio.org(orgId.value).audienceDemographics.index,
          },
          {
            name: t("layouts.studio.acquisition"),
            href: href.value.studio.org(orgId.value).audienceAcquisition.index,
          },
        ],
      },
      {
        name: t("layouts.studio.content"),
        icon: IconBook2,
        children: [
          {
            name: t("layouts.studio.titles"),
            href: href.value.studio.org(orgId.value).titles.index,
          },
          {
            name: t("layouts.studio.drafts"),
            href: withQuery(href.value.studio.org(orgId.value).titles.index, {
              state: "draft",
            }),
          },
        ],
      },
      {
        name: t("layouts.studio.engagement"),
        icon: IconAtom,
        children: [
          {
            name: t("layouts.studio.emotes"),
            href: href.value.studio.org(orgId.value).experience.emotes.index,
          },
          {
            name: t("layouts.studio.stickers"),
            href: href.value.studio.org(orgId.value).experience.stickers.index,
          },
          {
            name: t("layouts.studio.frames"),
            href: href.value.studio.org(orgId.value).experience.frames.index,
          },
        ],
      },
      {
        name: t("layouts.studio.subscriptions"),
        icon: IconCurrencyDollar,
        children: [
          {
            name: t("layouts.studio.overview"),
            href: href.value.studio.org(orgId.value).subscriptions.overview,
          },
          {
            name: t("layouts.studio.insights"),
            href: href.value.studio.org(orgId.value).subscriptions.insights,
          },
          {
            name: t("layouts.studio.balance"),
            href: href.value.studio.org(orgId.value).subscriptions.balance,
          },
          {
            name: t("layouts.studio.tiers"),
            href: href.value.studio.org(orgId.value).subscriptions.tiers,
          },
          {
            name: t("layouts.studio.account"),
            href: href.value.studio.org(orgId.value).subscriptions.account,
          },
        ],
      },
      {
        name: t("layouts.studio.settings"),
        icon: IconSettings,
        href: href.value.studio.org(orgId.value).settings.index,
      },
    ].filter(onlyTruthys),
  );

  return finalNav;
});
</script>
