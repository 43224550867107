<template>
  <div>
    <component
      :is="navItem.href && !navItem.children ? TheNuxtLink : 'div'"
      :class="{
        active:
          navItem.href && !navItem.children
            ? navItem.noNesting
              ? $route.path === navItem.href
              : $route.path.startsWith(navItem.href)
            : undefined,
      }"
      :to="navItem.href && !navItem.children ? navItem.href : undefined"
    >
      <div class="studio-nav-item" @click="toggleExpanded">
        <component v-if="navItem.icon" :is="navItem.icon" :size="24" />
        <template v-if="navItem.href && navItem.children">
          <TheNuxtLink :title="navItem.href">
            {{ navItem.name }}
          </TheNuxtLink>
        </template>
        <span v-else>
          {{ navItem.name }}
        </span>
        <template v-if="navItem.children">
          <NamiButton
            class="justify-self-end"
            v-if="navItem.href"
            :icon="IconChevronDown"
            button-type="secondary"
            text
            @buttonClick.stop.prevent="toggleExpanded"
          />
          <IconChevronDown class="!ml-auto" v-else-if="!expanded" />
          <IconChevronUp class="!ml-auto" v-else />
        </template>
      </div>
      <div class="pl-9 mt-1 grid gap-1" v-if="navItem.children && expanded">
        <StudioMenuNavItem
          v-for="child in navItem.children"
          :nav-item="child"
        />
      </div>
    </component>
  </div>
</template>

<script setup lang="ts">
import {
  IconChevronDown,
  IconPlaceholder,
  IconChevronUp,
} from "@tabler/icons-vue";

type NavItem = {
  name: string;
  icon?: typeof IconPlaceholder;
  href?: string;
  children?: NavItem[];
  collapsed?: boolean;
  noNesting?: boolean;
};

interface Props {
  navItem: NavItem;
}

const props = defineProps<Props>();

const TheNuxtLink = resolveComponent("TheNuxtLink");

const expanded = ref(!props.navItem.collapsed);

const toggleExpanded = () => (expanded.value = !expanded.value);
</script>

<style scoped lang="postcss">
.studio-nav-item {
  @apply hover:bg-neutral-100 dark:hover:!bg-neutral-800 text-neutral-500 hover:text-neutral-700 dark:hover:!text-neutral-300 w-full font-medium rounded-md px-3 py-2 flex items-center select-none space-x-3 cursor-pointer;
}

.active > .studio-nav-item {
  @apply bg-neutral-200 text-neutral-900 dark:bg-neutral-800 dark:text-neutral-100;
}
</style>
